export const homeObjOne1 = {
  lightBg: false,
  primary: true,
  imgStart: "",
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  buttonLabel: "Get Started",
  topLine: "Fuel Savings Program for Fleets",
  headline: "Maximize Your Fuel Savings",
  description1:
    "With the Carrier Purchasing Program, even small fleets can access significant fuel savings and added perks at all major truck stops nationwide with the best discounts available at TA and Petro locations.",
  start: "",
  //     <!-- Image courtesy of Easy-Peasy.AI -->
  img: require("./images/TruckGasStation.png"),
  alt: "Image",
};
export const homeObjOne2 = {
  lightBg: true,
  primary: false,
  imgStart: "start",
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  buttonLabel: "Get Started",
  topLine: "Unlock Exceptional Fuel Discounts Today!",
  headline: "Savings with Guaranteed Discounts on Diesel Fuel",
  description1: "A <span style={{ textDecoration: 'underline' }}>MINIMUM</span> guaranteed savings of $0.25 per gallon on #2 diesel off the posted cash price at all TA and Petro locations nationwide.  Current customers are averaging $0.40 off per gallon across the country at TA and Petro.",
  description2: "Additional discounts are provided at most truck stops nationwide with the largest savings at TA and Petro locations.",
  description3: "Universal acceptance with major fuel cards like AtoB, EFS, Comdata, TCH, Quick Q, Fleet One, and T-Check.",
  description4: "No fuel card? We can get you automatically approved, just link your current credit or debit card to your new fuel card.  Contact us for more information on obtaining a new fuel card.",
  start: "true",
  //     <!-- Image courtesy of Easy-Peasy.AI -->
  img: require("./images/TruckStop.png"),
  alt: "Image",
};
export const homeObjOne3 = {
  lightBg: false,
  primary: true,
  imgStart: "",
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  buttonLabel: "Get Started",
  topLine: "Exclusive Service Discounts for Fleet Maintenance!",
  headline: "Affordable Fleet Maintenance and Tire Savings at TA and Petro",
  description:
    "Take advantage of exclusive discounts on preventive maintenance and tire services at TA and Petro locations. Enjoy savings like $7.50 off Rapid PM, $15 off Premium PM, and $30 off Ultimate PM. Save $10 on DOT inspections for trailers, tractors, or both, and $15 off 3 Axle Alignments. Plus, keep your fleet rolling with $15 off each used tire and seasonal discounts on new tires. Make fleet maintenance more affordable today!",
  start: "true",
  //     <!-- Image courtesy of Gemini -->
  img: require("./images/TruckStopSquareAxle.png"),
  alt: "Image",
};

export const homeObjOne4 = {
  lightBg: true,
  primary: false,
  imgStart: "start",
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  buttonLabel: "Get Started",
  topLine: "Fuel Savings Now Available Nationwide!",
  headline: "Enjoy Unmatched Diesel Discounts Across the USA",
  description1: "Get the best discounts at TA and Petro locations nationwide.",
  description2: "Guaranteed minimum fuel discounts, not just a “fair” price.",
  description3: "Daily price emails so you know exactly what you’re paying.",
  description4: "Unlimited savings and no restrictions.",
  start: "",
  //     <!-- Image courtesy of Tra Waters -->
  img: require("./images/location-pins.png"),
  alt: "Image",
};
