import React from "react";
import { Button } from "../../GlobalStyles";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import {
  FooterContainer,
  FooterSubcription,
  FooterSubHeading,
  FooterSubText,
  
  FooterContactInfo,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from "./Footer.elements";
import { animateScroll as scroll } from 'react-scroll';
import logo from '../../Pages/images/FuelExpertsLogo-06.svg';
import {Link} from 'react-router-dom'
const toggleHome = () => {
  scroll.scrollToTop();
};

const Footer = () => {
  const getYear = () => {
    return new Date().getFullYear();
  }
  return (
    <FooterContainer>
      <FooterSubcription>
      <FooterContactInfo>
        <FooterSubHeading>
        <Link to="#" onClick={() => window.open("https://enrollment.myfueldeal.com", "_blank")}>
        <Button fontBig primary={true}>Sign Up</Button></Link>Today - It's Free and takes less than 1 minute!
        </FooterSubHeading>
        <FooterSubText>Save more with each mile, or reach out to our team for more details.</FooterSubText>
        </FooterContactInfo>
        
        <FooterContactInfo>
          <br />
          <FooterSubHeading>Get more information? Reach out to us!</FooterSubHeading>
          <FooterSubText>Email: <a style={{ color: '#FFF' }} href={`mailto:info@fuelexperts.com`}>info@fuelexperts.com</a></FooterSubText>
          <FooterSubText>Phone: 844-875-FUEL (3835)</FooterSubText>
        </FooterContactInfo>

      </FooterSubcription>



      <SocialMedia>
        <SocialMediaWrap>
          <SocialLogo to="/">
            <img src={logo} alt="Fuel Experts" onClick={toggleHome} />
          </SocialLogo>
          <WebsiteRights>My Fuel Deal | copyright © {getYear()}</WebsiteRights>
          <SocialIcons>
            <SocialIconLink href="/"  aria-label="Facebook">
              <FaFacebook />
            </SocialIconLink>
            <SocialIconLink href="/"  aria-label="Instagram">
              <FaInstagram />
            </SocialIconLink>
            <SocialIconLink href="/"  aria-label="Youtube">
              <FaYoutube />
            </SocialIconLink>
            <SocialIconLink href="/"  aria-label="Twitter">
              <FaTwitter />
            </SocialIconLink>
            <SocialIconLink href="/"  aria-label="LinkedIn">
              <FaLinkedin />
            </SocialIconLink>
          </SocialIcons>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
  );
};

export default Footer;
