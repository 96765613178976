import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { Button } from "../../GlobalStyles";
import { animateScroll as scroll } from 'react-scroll';
import {
  Nav,
  NavbarContainer,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavItemBtn,
  NavBtnLink,
} from "./Navbar.elements";

import logo from '../../Pages/images/FuelExpertsLogo-06.svg';

const Navbar = ({ scrollToSection, discounts, services  }) => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [button, setButton] = useState(true);
  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
};

const toggleBottom = () => {
  scroll.scrollToBottom();
};

  window.addEventListener("resize", showButton);
  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav>
          <NavbarContainer>
            {/* <NavLogo to="/">
            </NavLogo> */}
            <img src={logo} alt="Fuel Experts" onClick={toggleHome} />
            <MobileIcon onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </MobileIcon>
            <NavMenu onClick={handleClick} click={click}>
              <NavItem>
                <NavLinks to="/" onClick={toggleHome}>Home</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/#discounts"  onClick={() => scrollToSection(discounts)}>Discounts</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/#services" onClick={() => scrollToSection(services)}>Services</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/#contact" onClick={toggleBottom}>Contact Us</NavLinks>
              </NavItem>
              <NavItemBtn>
                {button ? (
                  <NavBtnLink to="#" onClick={() => window.open("https://enrollment.myfueldeal.com", "_blank")}>
                    <Button primary>Sign Up</Button>
                  </NavBtnLink>
                ) : (
                  <NavBtnLink to="#" onClick={() => window.open("https://enrollment.myfueldeal.com", "_blank")}>
                    <Button fontBig primary>
                      SIGN UP
                    </Button>
                  </NavBtnLink>
                )}
              </NavItemBtn>
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
