import React, { forwardRef } from "react";
import { Conatainer, Button } from "../../GlobalStyles";
import { Link } from "react-router-dom";
import {
  InfoSec,
  InfoRow,
  InfoColumn,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ImgWrapper,
  Img,
} from "./Discounts.element";

import flyer from '../../Pages/FuelExpertsFlyer.pdf'; 

const Discounts = forwardRef(
  (
    {
      primary,
      lightBg,
      imgStart,
      lightTopLine,
      lightText,
      lightTextDesc,
      buttonLabel,
      topLine,
      headline,
      // description1,
      description2,
      description3,
      description4,
      start,
      img,
      alt,
    },
    ref
  ) => {
    const description1 = (<>A <span style={{ textDecoration: 'underline' }}>MINIMUM</span> guaranteed savings of $0.25 per gallon on #2 diesel off the posted cash price at all TA and Petro locations nationwide.  Current customers are averaging $0.40 off per gallon across the country at TA and Petro.</>);
    return (
      <>
        <InfoSec ref={ref} lightBg={lightBg}>
          <Conatainer>
            <InfoRow imgStart={imgStart}>
              <InfoColumn>
                <TextWrapper>
                  <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                  <Heading lightText={lightText}>{headline}</Heading>
                  <Subtitle lightTextDesc={lightTextDesc}>{description1}</Subtitle>
                  <Subtitle lightTextDesc={lightTextDesc}>{description2}</Subtitle>
                  <Subtitle lightTextDesc={lightTextDesc}>{description3}</Subtitle>
                  <Subtitle lightTextDesc={lightTextDesc}>{description4}</Subtitle>
                  <Link
                    to="#"
                    onClick={() =>
                      window.open("https://enrollment.myfueldeal.com", "_blank")
                    }
                  >
                    <Button big fontBig primary={primary}>
                      {buttonLabel}
                    </Button>
                  </Link>
                    <a href={flyer} target='_blank' rel='noreferrer'>
                    <Button big fontBig primary={!primary}>
                      Download
                    </Button>
                    </a>
                </TextWrapper>
              </InfoColumn>
              <InfoColumn>
                <ImgWrapper start={start}>
                  <Img src={img} alt={alt} />
                </ImgWrapper>
              </InfoColumn>
            </InfoRow>
          </Conatainer>
        </InfoSec>
      </>
    );
  }
);

export default Discounts;
